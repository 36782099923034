import React, { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Logo from '../../images/Logo.png'; // Thêm import logo

interface RadarChartProps {
    lstChart: Array<{
        name: string;
        value1: number;
        value2: number;
        value3: number;
    }>;
}
const RadarChart: React.FC<RadarChartProps> = ({ lstChart }) => {
    const chartRef = useRef<HTMLDivElement>(null); // Tham chiếu đến div chứa biểu đồ

    useLayoutEffect(() => {
        if (!chartRef.current) return;

        // Khởi tạo root element
        const root = am5.Root.new(chartRef.current);

        // Gắn theme
        root.setThemes([am5themes_Animated.new(root)]);

        // Tạo biểu đồ radar
        const chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                innerRadius: am5.percent(25), // Tăng kích thước vòng tròn trong
                startAngle: -90,
                endAngle: 270,
                background: am5.Rectangle.new(root, {
                    fill: am5.color(0xffffff), // Thêm màu nền trắng
                    fillOpacity: 1
                })
            })
        );

        // Thêm cursor
        const cursor = chart.set(
            "cursor",
            am5radar.RadarCursor.new(root, {})
        );
        cursor.lineY.set("visible", false);

        // Tạo các trục và renderers
        const xRenderer = am5radar.AxisRendererCircular.new(root, {
            minGridDistance: 20,
            cellStartLocation: 0,
            cellEndLocation: 1
        });
        xRenderer.labels.template.setAll({
            radius: 10,
            fontSize: 12,
            textType: "circular"
        });

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "name",
                renderer: xRenderer,
                // tooltip: am5.Tooltip.new(root, {}),
            })
        );

        const yRenderer = am5radar.AxisRendererRadial.new(root, {
            minGridDistance: 20
        });

        yRenderer.labels.template.setAll({
            fontSize: 8,
            paddingRight: 0,
            textAlign: "start",
            centerX: am5.percent(100),
            centerY: am5.percent(50),
        });

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                min: 0,
                max: 5,
                strictMinMax: true,
            })
        );

        // Tạo 3 series với 3 màu khác nhau
        const series1 = chart.series.push(
            am5radar.RadarLineSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value1",
                categoryXField: "name",
                tooltip: am5.Tooltip.new(root, { labelText: "{valueY}" }),
                fill: am5.color(0x6794dc),
                stroke: am5.color(0x6794dc),
            })
        );
        series1.fills.template.setAll({
            fillOpacity: 0.3,
            visible: true
        });

        const series2 = chart.series.push(
            am5radar.RadarLineSeries.new(root, {
                name: "Series 2",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value2",
                categoryXField: "name",
                tooltip: am5.Tooltip.new(root, { labelText: "{valueY}" }),
                fill: am5.color(0x67dc94),
                stroke: am5.color(0x67dc94),
            })
        );
        series2.fills.template.setAll({
            fillOpacity: 0.3,
            visible: true
        });

        const series3 = chart.series.push(
            am5radar.RadarLineSeries.new(root, {
                name: "Series 3",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value3",
                categoryXField: "name",
                tooltip: am5.Tooltip.new(root, { labelText: "{valueY}" }),
                fill: am5.color(0xdc6794),
                stroke: am5.color(0xdc6794),
            })
        );
        series3.fills.template.setAll({
            fillOpacity: 0.3,
            visible: true
        });

        // Thêm bullets cho cả 3 series
        [series1, series2, series3].forEach(series => {
            series.strokes.template.setAll({ 
                strokeWidth: 2,
                strokeOpacity: 0.9
            });
            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        radius: 5,
                        fill: series.get("fill"),
                    }),
                    dynamic: true
                });
            });
        });

        // Cập nhật dữ liệu cho cả 3 series
        series1.data.setAll(lstChart);
        series2.data.setAll(lstChart);
        series3.data.setAll(lstChart);
        xAxis.data.setAll(lstChart);

        // Hiệu ứng
        series1.appear(1000);
        series2.appear(1000);
        series3.appear(1000);
        chart.appear(1000, 100);

        // Cleanup khi unmount component
        return () => {
            root.dispose();
        };
    }, [lstChart]);

    return (
        <div style={{
            position: 'relative'
        }}>
            <div ref={chartRef} style={{ width: "100%", height: "580px" }} />
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80px', // Điều chỉnh kích thước logo
                    height: '80px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1
                }}
            >
                <img 
                    src={Logo} 
                    alt="Logo" 
                    style={{
                        width: '60px', // Điều chỉnh kích thước của ảnh logo
                        height: 'auto',
                        objectFit: 'contain'
                    }}
                />
            </div>
            <div
                style={{
                    height: "20px",
                    width: '100%',
                    background: 'white',
                    position: 'absolute',
                    bottom: '00px',
                }}
            />
        </div>
    );
};

export default RadarChart;
