
import React, { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Logo from '../../images/Logo.png';

interface RadarChartProps {
    data: Array<{
        category: string;
        value: number;
    }>;
    color?: string;
}

const RadarAmChartSingle: React.FC<RadarChartProps> = ({ data, color = "#6794dc" }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!chartRef.current) return;

        const root = am5.Root.new(chartRef.current);
        root.setThemes([am5themes_Animated.new(root)]);

        const chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                innerRadius: am5.percent(25),
                startAngle: -90,
                endAngle: 270,
                background: am5.Rectangle.new(root, {
                    fill: am5.color(0xffffff),
                    fillOpacity: 1
                })
            })
        );

        const cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        const xRenderer = am5radar.AxisRendererCircular.new(root, {
            minGridDistance: 20
        });

        xRenderer.labels.template.setAll({
            radius: 10,
            fontSize: 12,
            textType: "circular"
        });

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "category",
                renderer: xRenderer
            })
        );

        const yRenderer = am5radar.AxisRendererRadial.new(root, {
            minGridDistance: 20
        });

        yRenderer.labels.template.setAll({
            fontSize: 8,
            textAlign: "center"
        });

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                min: 0,
                max: 5,
                strictMinMax: true
            })
        );

        const series = chart.series.push(
            am5radar.RadarLineSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "category",
                tooltip: am5.Tooltip.new(root, { labelText: "{valueY}" }),
                fill: am5.color(color),
                stroke: am5.color(color)
            })
        );

        series.fills.template.setAll({
            fillOpacity: 0.3,
            visible: true
        });

        series.strokes.template.setAll({
            strokeWidth: 2,
            strokeOpacity: 0.9
        });

        series.bullets.push(() => {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: series.get("fill")
                })
            });
        });

        series.data.setAll(data);
        xAxis.data.setAll(data);

        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [data, color]);

    return (
        <div style={{ position: 'relative' }}>
            <div ref={chartRef} style={{ width: "100%", height: "580px" }} />
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1
                }}
            >
                <img
                    src={Logo}
                    alt="Logo"
                    style={{
                        width: '60px',
                        height: 'auto',
                        objectFit: 'contain'
                    }}
                />
            </div>
            <div
                style={{
                    height: "20px",
                    width: '100%',
                    background: 'white',
                    position: 'absolute',
                    bottom: '00px',
                }}
            />
        </div>
    );
};

export default RadarAmChartSingle;